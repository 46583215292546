

.page_interdit {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}


.logo_interdit_velos {
    width: 200px;
    align-self: center;
    margin-top: 20px;
}