.connexion {
    margin: 5% 15%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}

.logo_connexion {
    width: 35%;
    margin-bottom: 10px;
    align-self: center;
}



.connexion_line {
    align-self: center;
    width: 100%;
    color: #00519E;
    padding-bottom: 10px;
    border-bottom: 2px solid #D8001C;
    margin-bottom: 8%;
    font-size: 34px;
}

.title_change_pwd{
    font-size: 30px;
    margin-bottom: 5%;
}

.before_input_email{
    font-size: 16px;
    margin-bottom: 2%;
}



.input_login {
    margin-bottom: 30px;
    align-self: center;
}

.remember {
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.btn_connexion{
    width: 200px;
    height: 40px;
    align-self: center;
}

.btn_change_password{
    margin-top: 15px;
    padding: 10px 20px;
    align-self: center;
    width: 130px;
}

.btn_save_password{
    padding: 10px 20px;
    align-self: center;
    width: 250px;
}



.line_envoyer{
    align-self: center;
}

.forgot_password {
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    /* text-decoration: none; */
    color: #00519E;
    font-size: 14px;
}

.goto_login{
    color: #00519E;
    font-size: 13px;
}



.MuiInputBase-input{
    font-size: 34px;
}

.msg_change_pwd{
    font-size: 14px;
    margin-bottom: 20px;

}

.pwd_equals{
    align-self: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.criteres_pwd{
    /* align-self: center; */
    font-size: 14px;
}


@media (max-width: 1000px) {

    .connexion {
        margin: 5%;
    }

    .logo_connexion {
        width: 75%;
        margin: 50px 0;
    }

    .connexion_line {
        font-size: 28px;
    }
    
    .input_login {
        width: 100%;
    }

}