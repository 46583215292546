body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #3A3A3A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:root {
  scrollbar-width: thin;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn-gfdj {
  position: relative;
  font-size: 16px;
  background-color: #1168d3;
  /* margin-right: 15px; */
  padding: .618rem 1.618rem;
  color: #FFF;
  text-shadow: rgba(0, 0, 0, .9) 0 3px 3px;
  border: 1px solid #242424;
  border-radius: 4px;
  box-shadow: rgba(255, 255, 255, .25) 0 1px 0, inset rgba(255, 255, 255, .25) 0 1px 0, inset rgba(0, 0, 0, .5) 0 0 0, inset rgba(255, 255, 255, .08) 0 1.25rem 0, inset rgba(0, 0, 0, .3) 0 -1.25rem 1.25rem, inset rgba(255, 255, 255, .1) 0 1.25rem 1.25rem;
  transition: all .2s linear;
  cursor: pointer;
}


.btn-gfdj:focus,
.btn-gfdj:hover {
  color: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, .5) 0 2px 5px, inset rgba(255, 255, 255, .25) 0 1px 0, inset rgba(0, 0, 0, .25) 0 0 0, inset rgba(255, 255, 255, .03) 0 20px 0, inset rgba(0, 0, 0, .15) 0 -20px 20px, inset rgba(255, 255, 255, .05) 0 20px 20px;
}

.btn-gfdj:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  font-style: italic;
  text-shadow: none;
}


.bloc {
  margin: 15px 15px 0px 15px;
}

h1 {
  color: #00519E;
  font-size: 24px;
}

h2 {
  color: #A4BFCC;
  margin: 8px 0px;
  border-bottom: 1px solid #A4BFCC;
  font-size: 20px;
}


@media (max-width: 1000px) {

  h1 {
    font-size: 17px;
  }

  h2 {
    font-size: 16px;
  }
}