




/* Pour que le texte over trombi puisse être centré par rapport à l'image
https://www.w3schools.com/howto/howto_css_image_text.asp */
.container_trombine {
    position: relative;
    text-align: center;
    border-radius: inherit;
  }


.username_over_trombi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    color: #696969;
    font-style: italic;
  }


.trombine {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: inherit;
}

.trombine_select {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: inherit;
}


.trombine:hover{
    -webkit-filter: brightness(70%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.trombine_select:hover{
    cursor: pointer;
}


.avatar_flag{
    position: relative;
}

.avatar_flag:hover{
    cursor: pointer;
}

.avatar_link{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flag {
    width: 20%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}


.user {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
}

.fonction {
    font-size: 11px;
    text-align: center;
}


/* conteneur trombine + texte en-dessous */
.container_avatar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 6px ;
    width: 80px;
}

.section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.section::after {
    content: "";
    flex: auto;
  }

 /* Tooltip container */
 /* https://www.w3schools.com/howto/howto_css_tooltip.asp */
 .tooltip_avatar {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltip_avatar .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip_avatar .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip_avatar:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  } 




.border_red {
    width: 94%;
    height: 94%;
    border: 3px solid;
    border-color: #f44336;
}

.border_green {
    width: 94%;
    height: 94%;
    border: 3px solid;
    border-color: #4caf50;
}

.border_orange {
    width: 94%;
    height: 94%;
    border: 3px solid;
    border-color: #ff9800;
}


.row_effectifs {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}




/* @media only screen and (max-width: 1000px) {
    .responsive {
        width: 10%;
    }
}

@media only screen and (max-width: 800px) {
    .responsive {
        width: 15%;
    }
}

@media only screen and (max-width: 600px) {
    .responsive {
        width: 30%;
    }
}

@media only screen and (max-width: 400px) {
    .responsive {
        width: 48%;
    }
}

@media only screen and (max-width: 300px) {
    .responsive {
        width: 100%;
    }
} */