/* Ici pour le flex avec dernier item div à droite : https://stackoverflow.com/questions/35269947/how-can-i-align-one-item-right-with-flexbox */

.trombi_mes_coureurs {
	margin-left: auto;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
}

.avatar_banner {
	width: 40px;
	margin-right: 5px;
	font-size: 9px;
	/* border-radius: 20px;
	overflow: hidden; */
}

.coureur_focus {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 15px;
}

.coureur_focus_txt {
	font-size: 13px;
	color: white;
}


.icon_little_menu {
	height: 30px;
	width: 30px;
	margin: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.banner_large {
	overflow-x: hidden;
	display: flex;
	flex-direction: row;
	/* justify-content: flex-start; */
	align-items: center;
	/* width: 100%; */
	border-bottom: solid 2.5px #D8001C;
	background-color: #00499a;
	color: white;
	padding: 0px;
	/* margin-left: auto; */
	height: 65px;
	position: sticky;
	top: 0;
	z-index: 999;
	height: 70px;
}

.logo_banner {
	margin: 0px 20px;
	height: 60px;
}


.icon_open_menu {
	margin-left: 15px;
}

.citation {
	font-size: 18px;
	font-style: italic;
	font-family: cursive;
}

.plateforme_locale{
	margin-left: 30px;
	font-size: 20px;
}




@media (max-width: 1000px) {
	.logo_banner {
		display: none;
	}

	.icon_open_menu {
		display: none;
	}

	.avatar_banner {
		width: 24px;
		margin-right: 2px;
		font-size: 8px;
	}

	.coureur_focus_txt {
		font-size: 10px;
		max-width: 50px;
	}

	.banner_large {
		height: 50px;
	}

	.citation {
		display: none;
	}

	.plateforme_locale{
		margin-left: 0px;
		font-size: 12px;
	}

}


@media (min-width: 1000px) {
	.menu_small {
		display: none;
	}

}
