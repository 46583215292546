/* Ici pour le flex avec dernier item div à droite : https://stackoverflow.com/questions/35269947/how-can-i-align-one-item-right-with-flexbox */

.page_menu {
	scrollbar-width: thin;
	display: flex;
	/* flex-direction: row; */
	/* justify-content: space-between; */
	/* align-items: center; */
}


.icon_menu {
	height: 25px;
	margin-right: 10px;
}

.drawer_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 65px
}


.avatar_connected {
	width: 50px;
	margin-top: 10px;
	margin-left: 70px;
	/* flex-grow: 2; */
}


.icon_item {
	color: white;
}


.main_page {
    margin: 10px;
    box-sizing: border-box;
}


@media (max-width: 1000px) {
    .main_page {
        margin: 3px;
    }
}
